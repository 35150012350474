
export default {
  name: "home-view",
  data() {
    return {
      // now: dayjs().locale("es").format("DD/MMMM/YYYY HH:mm:ss"),
    };
  },
  mounted() {},
  methods: {},
};
